import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { SiteLink } from "../../../components";
import Slider from "react-slick";

export function PageTilesSmall(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const posts = getPages(cmsUtils.payload(item, "PageObjects"));
  const numOfTileLines = parseInt(
    cmsUtils.payload(item, "NumOfTileLines", "2")
  );
  const bp = utils.ui.getCurrentBreakpoint();
  const tilesPerLine = { xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 };
  const numOfPost = numOfTileLines * (tilesPerLine[bp] || 3);
  const step = numOfPost;

  const [tilesToShow, setTilesToShow] = React.useState(numOfPost);

  const displayingPosts =
    posts.length > tilesToShow ? posts.slice(0, tilesToShow) : posts;
  const showMore = posts.length > displayingPosts.length;

  const useSlider = cmsUtils.payload(item, "UseSlider") === "slider";
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const pageTileElts = displayingPosts.map((page, index) => (
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  ));

  const pageTileEltsAll = posts.map((page, index) => (
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  ));

  const cssClass = utils.classNames(
    "cms_item",
    "pageTilesSmall tiles",
    useSlider && "useSlider",
    item.cssClass || item.anchorName || ""
  );

  if (useSlider) {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <Slider {...settings}>{pageTileEltsAll}</Slider>
      </div>
    );
  } else {
    return (
      <>
        <div
          className={cssClass}
          data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
          id={item.anchorName}
        >
          {pageTileElts}
        </div>
        {showMore && (
          <div className='blogTiles__loadMore'>
            <span
              className='thinBtn'
              onClick={(e) => {
                setTilesToShow(tilesToShow + step);
              }}
            >
              LOAD MORE
            </span>
          </div>
        )}
      </>
    );
  }
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;

  function truncateAfterWord(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }
    
    const truncated = str.slice(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    if (lastSpaceIndex === -1) {
      return truncated + '...';
    }

    return truncated.slice(0, lastSpaceIndex) + '...';
  }

  const truncatedDesc = truncateAfterWord(page.tileDesc, 120);

  return (
    <div className='pageTileSmall'>
      <SiteLink
        className='pageTileSmall__link'
        to={disableLink ? "" : page.pageUrl}
      >
        <div
          className='pageTileSmall__bg'
          style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
        >
        </div>
        <div className='pageTileSmall__content'>
          <div className='pageTileSmall__titleSmall'>{page.pageTitle}</div>
          <div className='pageTileSmall__desc'>
            {truncatedDesc}
          </div>
          <div className='pageTileSmall__btn'>
            Learn More<span className='blogTile__btn-arrow'></span>
          </div>
        </div>
      </SiteLink>
    </div>
  );
}
